import React from "react"
import "./PartnersHowItWorks.scss"

import { useTranslation } from "react-i18next";

const steps = [
  {
    title: "partnersHowItWorks.steps.item-1.title",
    text: "partnersHowItWorks.steps.item-1.text",
  },
  {
    title: "partnersHowItWorks.steps.item-2.title",
    text: "partnersHowItWorks.steps.item-2.text",
  },
  {
    title: "partnersHowItWorks.steps.item-3.title",
    text: "partnersHowItWorks.steps.item-3.text",
  },
]

function PartnersHowItWorks() {
  const { t } = useTranslation()

  return (
    <section className="partners-how-it-works">
      <h3>{t("partnersHowItWorks.title")}</h3>

      <div>
        {steps.map(({ title, text }, i) => {
          return (
            <div key={i}>
              <span>{i + 1}</span>
              <h5>{t(title)}</h5>
              <p>{t(text)}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PartnersHowItWorks
