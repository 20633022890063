import React from "react"
import "./PartnersBenefits.scss"

import { useTranslation } from "react-i18next";

const benefits = [
  {
    title: "partnersBenefits.benefits.item-1.title",
    text: "partnersBenefits.benefits.item-1.text",
  },
  {
    title: "partnersBenefits.benefits.item-2.title",
    text: "partnersBenefits.benefits.item-2.text",
  },
  {
    title: "partnersBenefits.benefits.item-3.title",
    text: "partnersBenefits.benefits.item-3.text",
  },
]

function PartnersBenefits() {
  const { t } = useTranslation()

  return (
    <section className="partners-benefits">
      <h3>{t("partnersBenefits.title")}</h3>

      <div>
        {benefits.map(({ title, text }) => {
          return (
            <div key={title}>
              <h5>{t(title)}</h5>
              <p>{t(text)}</p>
            </div>
          )
        })}
      </div>
    </section>
  )
}

export default PartnersBenefits
