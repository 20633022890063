import React from "react"
import Input from "../common/Input/Input"
import Button from "../common/Button/Button"
import "./Top.scss"
import classNames from "classnames"
import { useTranslation } from "react-i18next";
import { StaticImage } from "gatsby-plugin-image"
import { useMediaQuery } from "../hooks/useMediaQuery.js"
import { checkIsAllsportsData } from "../../domain_utils"

function Top({ type, startRef, email, setEmail, transferEmail, platinum_amount , country}) {
  const { t } = useTranslation()
  let isPageWide = useMediaQuery("(min-width: 1280px)")
  let isPageMedium = useMediaQuery("(min-width: 768px)")
  const brandType = checkIsAllsportsData(country)
  const brand = brandType.brand

  const texts = {
    clients: {
      title: t("top.texts.clients.title", { brand }),
      description: t("top.texts.clients.description"),
    },
    companies: {
      title: t("top.texts.companies.title", { brand }),
      description: t("top.texts.companies.description", {name:  platinum_amount})
    },
    partners: {
      title: t("top.texts.partners.title"),
      description: t("top.texts.partners.description",  { brand }),
    },
  }
  const { title, description } = texts[type]
  function submit(event) {
    event.preventDefault()
    transferEmail(email)
    if (startRef.current) {
      startRef.current.scrollIntoView()
    }
  }

  return (
    <section className={classNames("top", type)}>
      <div className="afterClass">
        {!isPageWide && type === "companies" ? (
          <StaticImage
            src="./images/couple2.png"
            alt="couple"
            placeholder="blurred"
            layout="constrained"
            height={429}
          />
        ) : !isPageWide && !isPageMedium && type === "clients" ? (
          <StaticImage
            src="./images/girl2.png"
            alt="girl"
            placeholder="blurred"
            layout="constrained"
            width={252}
          />
        ) : type === "partners" ? (
          <StaticImage
            src="./images/mens.jpg"
            alt="mens"
            placeholder="blurred"
            layout="constrained"
            height={429}
          />
        ) : !isPageWide && isPageMedium && type === "clients" ? (
          <StaticImage
            src="./images/girl2.png"
            alt="girl"
            layout="constrained"
          />
        ) : isPageWide && type === "companies" ? (
          <StaticImage
            src="./images/couple2.png"
            alt="couple"
            placeholder="blurred"
            layout="constrained"
            width={1180}
          />
        ) : isPageWide && type === "clients" ? (
          <StaticImage
            src="./images/girl2.png"
            alt="girl"
            layout="constrained"
            width={405}
          />
        ) : null}
      </div>
      <h1 className="title">{title}</h1>
      <p>{description}</p>

      <form onSubmit={submit}>
        <Input
          required="required"
          type="email"
          value={email}
          name="email"
          onChange={setEmail}
          placeholder="E-mail"
          autoComplete="email"
        />
        <Button color="blue">{t("top.buttonTitle")}</Button>
      </form>
    </section>
  )
}

export default Top
